import {Outlet, Navigate} from 'react-router-dom';
import {useStores} from '../../stores';

export const PublicRoutes = () => {
  const store = useStores();

  const useAuth = () => {
    const accessToken =
      store &&
      store.user &&
      store.user.credentials &&
      store.user.credentials.accessToken;
    if (accessToken) {
      return true;
    }
    return false;
  };

  const isAuthenticated = useAuth();

  if (isAuthenticated) {
    return <Navigate replace to={'/user_admin'} />;
  }
  return <Outlet />;
};
