export const storageKeys = {
  loginCredentials: 'LOGIN_CREDENTIALS',
  profileImage: 'PROFILE_IMAGE',
};

export const tabs = {
  signup: 'SIGNUP',
  payPerUse: 'PAY_PER_USE',
  login: 'LOGIN',
};

export const authProviders = {
  google: 'google',
  facebook: 'facebook',
  apple: 'apple',
  twitter: 'twitter',
};

export const adminList = {
  profile: 'PROFILE',
  reportProblem: 'REPORT_PROBLEM',
  getBags: 'GET_BAGS',
  returnBags: 'RETURN_BAGS',
  redeemRewards: 'REDEEM_REWARDS',
  paymentMethod: 'PAYMENT_METHOD',
  orderHistory: 'ORDER_HISTORY',
  cancelSubscription: 'CANCEL_SUBSCRIPTION',
  logout: 'LOGOUT',
};

export const genderData = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'NB',
    label: 'NB',
  },
  {
    value: 'Prefer Not To Say',
    label: 'Prefer Not To Say',
  },
];

export const ageGroupData = [
  {
    value: '13-17',
    label: '13-17',
  },
  {
    value: '18-34',
    label: '18-34',
  },
  {
    value: '35-50',
    label: '35-50',
  },
  {
    value: '51-70',
    label: '51-70',
  },
  {
    value: 'Above 70',
    label: 'Above 70',
  },
  {
    value: 'Prefer Not To Say',
    label: 'Prefer Not To Say',
  },
];

export const emailTopicsData = [
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'Technical',
    label: 'Technical',
  },
  {
    value: 'Feedback',
    label: 'Feedback',
  },
];

export const xssTags = [/<script>/i];

export const paymentTypes = {
  subscription: 'subscription',
  ppu: 'ppu',
};
