import {useState, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import ReactCrop, {centerCrop, makeAspectCrop, Crop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import {Image, Text} from '..';
import {ReactComponent as CloseSvg} from '../../assets/close.svg';
import {ReactComponent as DefaultProfileSvg} from '../../assets/default-profile.svg';
import {ReactComponent as TickSvg} from '../../assets/tick.svg';

import {useStores} from '../../stores';
import {useSnackbar} from '../../contexts/SnackbarProvider';
import {getBase64} from '../../config/utils';

import * as Styles from './profileImage.styles';

export const ProfileImage = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [croppedImage, setCroppedImage] = useState<any>('');

  const imgRef = useRef<HTMLImageElement>(null);

  const store = useStores();
  const snackbar = useSnackbar();

  const profileData = store && store.user && store.user.profile;

  /**
   * Function on click close button
   */
  const onClickClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Function on click done button
   */
  const onClickDone = () => {
    if (croppedImage) {
      store.user.saveProfileImage(croppedImage);
      snackbar.success('snackbar.successMessages.updateProfileImageSuccess');
      setIsModalOpen(false);
    }
  };

  /**
   * Get cropped image data
   */
  const getCroppedImage = (sourceImage: any, cropConfig: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(
        sourceImage,
        cropConfig.x * scaleX,
        cropConfig.y * scaleY,
        cropConfig.width * scaleX,
        cropConfig.height * scaleY,
        0,
        0,
        cropConfig.width,
        cropConfig.height,
      );
    }
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  /**
   * Crop the image and convert it to base64
   */
  async function cropImage(cropConfig: any) {
    if (imgRef && cropConfig.width && cropConfig.height) {
      const croppedImg = await getCroppedImage(imgRef.current, cropConfig);
      getBase64(croppedImg)
        .then((result: any) => {
          if (result) {
            setCroppedImage(result);
          }
        })
        .catch(() => {
          snackbar.error('snackbar.errorMessages.updateProfileImageFail');
        });
    }
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspectRatio: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspectRatio,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  /**
   * Function on loading image
   */
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const {width, height} = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1 / 1));
  }

  /**
   * Function on selecting an image file
   */
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader && reader.result) {
          setImgSrc(reader.result.toString() || '');
          setIsModalOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Box>
      <input
        type="file"
        id="profile-image"
        accept="image/*"
        style={{display: 'none'}}
        onChange={onSelectFile}
      />
      <label htmlFor="profile-image">
        <Box sx={Styles.profileImageContainer}>
          {profileData && profileData.image ? (
            <Image imgSrc={profileData.image} imgHeight={52} rounded={true} />
          ) : (
            <DefaultProfileSvg height={58} width={58} />
          )}
        </Box>
      </label>
      <Backdrop
        sx={{zIndex: theme => theme.zIndex.drawer + 10}}
        open={isModalOpen}
      >
        <Box sx={Styles.modalContentMainContainer}>
          <Box sx={Styles.modalHeaderContainer}>
            <Box
              sx={Styles.closeButtonContainer}
              onClick={() => onClickClose()}
            >
              <CloseSvg height={15} width={15} />
            </Box>
            <Text
              variant="h6"
              sx={Styles.editText}
              tx={'common.editPhoto'}
            ></Text>
            <Box sx={Styles.closeButtonContainer} onClick={() => onClickDone()}>
              <TickSvg height={15} width={15} />
            </Box>
          </Box>
          <Box sx={Styles.cropperContainer}>
            <ReactCrop
              style={{maxHeight: '75vh'}}
              crop={crop}
              aspect={1 / 1}
              keepSelection={true}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={cropConfig => cropImage(cropConfig)}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  objectFit: 'contain',
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Box>
        </Box>
      </Backdrop>
    </Box>
  );
});
