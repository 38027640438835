import {flow, Instance, SnapshotOut, types} from 'mobx-state-tree';
import {
  SignupParams,
  LoginParams,
  LoginResponse,
  ForgotPasswordParams,
  ValidateResetCodeParams,
  ResetPasswordParams,
  ProfileResponse,
  UpdateProfileParams,
  ChangePasswordParams,
  UserAgreementParams,
} from '../../services/api/user/user.type';

import {withEnvironment} from '../extensions/with-environment';

const SignupApiModel = types.model({
  status: types.maybe(types.string),
  activeTab: types.maybe(types.string),
});

const LoginApiModel = types.model({
  accessToken: types.maybe(types.string),
  refreshToken: types.maybe(types.string),
  refreshExpiresIn: types.maybe(types.number),
  scope: types.maybe(types.string),
  tokenType: types.maybe(types.string),
  sessionState: types.maybe(types.string),
  expiresIn: types.maybe(types.number),
});

const UserProfileModel = types.model({
  id: types.maybe(types.string),
  email: types.maybe(types.string),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  shortBio: types.maybe(types.string),
  image: types.maybe(types.string),
  zipcode: types.maybe(types.string),
  unitNumber: types.maybe(types.number),
  program: types.maybe(types.string),
  type: types.maybe(types.string),
  rewards: types.maybe(types.number),
  gameRewards: types.maybe(types.number),
  pointsRedeemed: types.maybe(types.number),
  orders: types.maybe(types.number),
  phoneNumber: types.maybe(types.string),
  role: types.maybe(types.string),
  active: types.maybe(types.boolean),
  address: types.maybe(types.string),
  ghgScore: types.maybe(types.number),
  gender: types.maybe(types.string),
  badge: types.maybe(types.string),
  reuseCount: types.maybe(types.number),
  ageGroup: types.maybe(types.string),
  state: types.maybe(types.string),
  city: types.maybe(types.string),
  createdDate: types.maybe(types.string),
  lastModifiedDate: types.maybe(types.string),
});

/**
 * User model.
 */
export const UserModel = types
  .model('user')
  .props({
    signupData: types.optional(SignupApiModel, {}),
    credentials: types.optional(LoginApiModel, {}),
    profile: types.optional(UserProfileModel, {}),
  })
  .extend(withEnvironment)
  .actions(self => ({
    /* eslint-disable no-param-reassign */
    signupUser: (props: SnapshotOut<typeof SignupApiModel>) => {
      if (props.status) {
        self.signupData.status = props.status;
      }
    },
    loginUser: (props: SnapshotOut<typeof LoginApiModel>) => {
      if (props.accessToken) {
        self.credentials.accessToken = props.accessToken;
        self.credentials.refreshToken = props.refreshToken;
        self.credentials.refreshExpiresIn = props.refreshExpiresIn;
        self.credentials.scope = props.scope;
        self.credentials.tokenType = props.tokenType;
        self.credentials.sessionState = props.sessionState;
        self.credentials.expiresIn = props.expiresIn;
      }
    },
    saveUserProfile: (props: SnapshotOut<typeof UserProfileModel>) => {
      if (props.id) {
        self.profile.id = props.id;
      }
      if (props.email) {
        self.profile.email = props.email;
      }
      if (props.firstName) {
        self.profile.firstName = props.firstName;
      }
      if (props.lastName) {
        self.profile.lastName = props.lastName;
      }
      if (props.shortBio) {
        self.profile.shortBio = props.shortBio;
      }
      if (props.image) {
        self.profile.image = props.image;
      }
      if (props.zipcode) {
        self.profile.zipcode = props.zipcode;
      }
      if (props.unitNumber) {
        self.profile.unitNumber = props.unitNumber;
      }
      if (props.program) {
        self.profile.program = props.program;
      }
      if (props.type) {
        self.profile.type = props.type;
      }
      if (props.rewards) {
        self.profile.rewards = props.rewards;
      }
      if (props.gameRewards) {
        self.profile.gameRewards = props.gameRewards;
      }
      if (props.pointsRedeemed) {
        self.profile.pointsRedeemed = props.pointsRedeemed;
      }
      if (props.orders) {
        self.profile.orders = props.orders;
      }
      if (props.phoneNumber) {
        self.profile.phoneNumber = props.phoneNumber;
      }
      if (props.role) {
        self.profile.role = props.role;
      }
      if (props.active) {
        self.profile.active = props.active;
      }
      if (props.address) {
        self.profile.address = props.address;
      }
      if (props.ghgScore) {
        self.profile.ghgScore = props.ghgScore;
      }
      if (props.gender) {
        self.profile.gender = props.gender;
      }
      if (props.badge) {
        self.profile.badge = props.badge;
      }
      if (props.reuseCount) {
        self.profile.reuseCount = props.reuseCount;
      }
      if (props.ageGroup) {
        self.profile.ageGroup = props.ageGroup;
      }
      if (props.state) {
        self.profile.state = props.state;
      }
      if (props.city) {
        self.profile.city = props.city;
      }
      if (props.createdDate) {
        self.profile.createdDate = props.createdDate;
      }
      if (props.lastModifiedDate) {
        self.profile.lastModifiedDate = props.lastModifiedDate;
      }
    },
    saveProfileImage: (imageData: string) => {
      if (imageData) {
        self.profile.image = imageData;
      }
    },
    setActiveTab: (tab: string) => {
      if (tab) {
        self.signupData.activeTab = tab;
      } else {
        self.signupData.activeTab = '';
      }
    },
    /* eslint-enable no-param-reassign */
  }))
  .actions(self => ({
    /**
     * Signup using phone, email and password.
     */
    signup: flow(function* userSignup(params: SignupParams) {
      const signupResult: string = yield self.environment.api.user.signup(
        params,
      );
      self.signupUser(
        SignupApiModel.create({
          status: signupResult,
        }),
      );
      return signupResult;
    }),

    /**
     * Sign up using Google.
     */
    googleSignup: flow(function* userGoogleSignup(accessToken: string) {
      const signupResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'google',
          accessToken: `Bearer ${accessToken}`,
        });
      return signupResult;
    }),

    /**
     * Sign up using Facebook.
     */
    facebookSignup: flow(function* userFacebookSignup(accessToken: string) {
      const signupResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'facebook',
          accessToken: `Bearer ${accessToken}`,
        });
      return signupResult;
    }),

    /**
     * Sign up using Apple.
     */
    appleSignup: flow(function* userAppleSignup(accessToken: string) {
      const signupResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'apple',
          accessToken: `Bearer ${accessToken}`,
        });
      return signupResult;
    }),

    /**
     * Sign up using Twitter.
     */
    twitterSignup: flow(function* userTwitterSignup(
      accessToken: string,
      secret?: string,
    ) {
      const signupResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'twitter',
          accessToken: secret ? accessToken : `Bearer ${accessToken}`,
          secret: secret,
        });
      return signupResult;
    }),

    /**
     * Sign in using Google.
     */
    googleLogin: flow(function* userGoogleLogin(accessToken: string) {
      const loginResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'google',
          accessToken: `Bearer ${accessToken}`,
        });
      self.loginUser(
        LoginApiModel.create({
          accessToken: loginResult.access_token,
          refreshToken: loginResult.refresh_token,
          refreshExpiresIn: loginResult.refresh_expires_in,
          scope: loginResult.scope,
          tokenType: loginResult.token_type,
          sessionState: loginResult.session_state,
          expiresIn: loginResult.expires_in,
        }),
      );
      return loginResult;
    }),

    /**
     * Sign in using Facebook.
     */
    facebookLogin: flow(function* userFacebookLogin(accessToken: string) {
      const loginResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'facebook',
          accessToken: `Bearer ${accessToken}`,
        });
      self.loginUser(
        LoginApiModel.create({
          accessToken: loginResult.access_token,
          refreshToken: loginResult.refresh_token,
          refreshExpiresIn: loginResult.refresh_expires_in,
          scope: loginResult.scope,
          tokenType: loginResult.token_type,
          sessionState: loginResult.session_state,
          expiresIn: loginResult.expires_in,
        }),
      );
      return loginResult;
    }),

    /**
     * Sign in using Apple.
     */
    appleLogin: flow(function* userAppleLogin(accessToken: string) {
      const loginResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'apple',
          accessToken: `Bearer ${accessToken}`,
        });
      self.loginUser(
        LoginApiModel.create({
          accessToken: loginResult.access_token,
          refreshToken: loginResult.refresh_token,
          refreshExpiresIn: loginResult.refresh_expires_in,
          scope: loginResult.scope,
          tokenType: loginResult.token_type,
          sessionState: loginResult.session_state,
          expiresIn: loginResult.expires_in,
        }),
      );
      return loginResult;
    }),

    /**
     * Sign in using Twitter.
     */
    twitterLogin: flow(function* userTwitterLogin(
      accessToken: string,
      secret?: string,
    ) {
      const loginResult: LoginResponse =
        yield self.environment.api.user.socialLogin({
          provider: 'twitter',
          accessToken: secret ? accessToken : `Bearer ${accessToken}`,
          secret: secret,
        });
      self.loginUser(
        LoginApiModel.create({
          accessToken: loginResult.access_token,
          refreshToken: loginResult.refresh_token,
          refreshExpiresIn: loginResult.refresh_expires_in,
          scope: loginResult.scope,
          tokenType: loginResult.token_type,
          sessionState: loginResult.session_state,
          expiresIn: loginResult.expires_in,
        }),
      );
      return loginResult;
    }),

    /**
     * Login using phone/email and password.
     */
    login: flow(function* UserLogin(params: LoginParams) {
      const loginResult: LoginResponse = yield self.environment.api.user.login(
        params,
      );
      self.loginUser(
        LoginApiModel.create({
          accessToken: loginResult.access_token,
          refreshToken: loginResult.refresh_token,
          refreshExpiresIn: loginResult.refresh_expires_in,
          scope: loginResult.scope,
          tokenType: loginResult.token_type,
          sessionState: loginResult.session_state,
          expiresIn: loginResult.expires_in,
        }),
      );
      return loginResult;
    }),

    /**
     * Send a password reset email.
     */
    forgetPassword: flow(function* forgetUserLoginPassword(
      params: ForgotPasswordParams,
    ) {
      const result: any = yield self.environment.api.user.forgetPassword(
        params,
      );
      result.params = params;
      return result;
    }),

    /**
     * Validate reset code
     */
    validateResetCode: flow(function* validateUserResetCode(
      params: ValidateResetCodeParams,
    ) {
      const result: any = yield self.environment.api.user.validateResetCode(
        params,
      );
      result.params = params;
      return result;
    }),

    /**
     * Reset password
     */
    resetPassword: flow(function* resetUserPassword(
      params: ResetPasswordParams,
    ) {
      const result: any = yield self.environment.api.user.resetPassword(params);
      return result;
    }),

    /**
     * Fetch user profile.
     */
    fetchProfile: flow(function* fetchUserProfile() {
      const fetchProfileResult: ProfileResponse =
        yield self.environment.api.user.fetchProfile({
          Authorization: `Bearer ${self.credentials.accessToken}`,
        });
      self.saveUserProfile(
        UserProfileModel.create({
          id: fetchProfileResult.id,
          email: fetchProfileResult.email || undefined,
          firstName: fetchProfileResult.firstName || undefined,
          lastName: fetchProfileResult.lastName || undefined,
          shortBio: fetchProfileResult.shortBio || undefined,
          zipcode: fetchProfileResult.zipcode || undefined,
          unitNumber: fetchProfileResult.unitNumber || undefined,
          program: fetchProfileResult.program || undefined,
          type: fetchProfileResult.type || undefined,
          rewards: fetchProfileResult.rewards || undefined,
          gameRewards: fetchProfileResult.gameRewards || undefined,
          pointsRedeemed: fetchProfileResult.pointsRedeemed || undefined,
          orders: fetchProfileResult.orders || undefined,
          phoneNumber: fetchProfileResult.phoneNumber || undefined,
          role: fetchProfileResult.role || undefined,
          active: fetchProfileResult.active || undefined,
          address: fetchProfileResult.address || undefined,
          ghgScore: fetchProfileResult.ghgScore || undefined,
          gender: fetchProfileResult.gender || undefined,
          badge: fetchProfileResult.badge || undefined,
          reuseCount: fetchProfileResult.reuseCount || undefined,
          ageGroup: fetchProfileResult.ageGroup || undefined,
          state: fetchProfileResult.state || undefined,
          city: fetchProfileResult.city || undefined,
          createdDate: fetchProfileResult.createdDate || undefined,
          lastModifiedDate: fetchProfileResult.lastModifiedDate || undefined,
        }),
      );
      return fetchProfileResult;
    }),

    /**
     * Update user profile
     */
    updateProfile: flow(function* updateUserProfile(
      params: UpdateProfileParams,
    ) {
      const requestParams = {
        ...self.profile, // Set params from existing data
        ...params, // Update params with new data
      };
      const requestHeaders = {
        Authorization: `Bearer ${self.credentials.accessToken}`,
      };
      const result: ProfileResponse =
        yield self.environment.api.user.updateProfile(
          requestParams,
          requestHeaders,
        );
      return result;
    }),

    /**
     * Change user password
     */
    changePassword: flow(function* request(params: ChangePasswordParams) {
      /* eslint-disable no-param-reassign */
      params.email = self.profile.email;
      /* eslint-enable no-param-reassign */
      const requestHeaders = {
        Authorization: `Bearer ${self.credentials.accessToken}`,
      };
      const result: ProfileResponse =
        yield self.environment.api.user.changePassword(params, requestHeaders);
      return result;
    }),

    /**
     * Delete user data
     */
    deleteUserData: flow(function* request() {
      const requestHeaders = {
        Authorization: `Bearer ${self.credentials.accessToken}`,
      };
      const result: ProfileResponse =
        yield self.environment.api.user.deleteUserData(requestHeaders);
      return result;
    }),

    /**
     * Accept user agreement.
     */
    acceptUserAgreement: flow(function* request(params: UserAgreementParams) {
      const result: any = yield self.environment.api.user.userAgreement(params);
      result.params = params;
      return result;
    }),
  }));

type UserType = Instance<typeof UserModel>;

export interface User extends UserType {}

type UserSnapshotType = SnapshotOut<typeof UserModel>;

export interface UserSnapshot extends UserSnapshotType {}

export const createUserDefaultModel = () =>
  types.optional(UserModel, {} as any);
