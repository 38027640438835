import {SxProps} from '@mui/material';
import {CSSProperties} from 'react';

// the styling for the background container
export const backgroundContainer: SxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#75DBDB15',
};

// the styling for the tutorial main container
export const tutorialMainContainer: SxProps = {
  width: '100%',
  maxWidth: '450px',
  height: '100%',
  position: 'relative',
  backgroundColor: 'transparent',
};

// the styling for the black color text
export const blackText: SxProps = {
  color: '#000000',
};

// the styling for the reuse icon container
export const reuseIconContainer: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '8.1vh',
};

// the styling for the carousel container
export const carouselContainerDiv: SxProps = {
  width: '100%',
  maxWidth: '450px',
  marginTop: '47px',
};

// the styling for the carousel item container
export const carouselItemContainerDiv: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

// the styling for the image container
export const imageContainerDiv: SxProps = {
  width: '100%',
  height: '50vh',
};

// the styling for the title text container
export const titleTextContainer: SxProps = {
  width: '268px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '6.2vh',
};

// the styling for the title text
export const titleText: SxProps = {
  fontFamily: 'Roboto',
  color: '#000000',
  fontSize: '24px',
  fontWeight: 'fontWeightBold',
  lineHeight: '28px',
};

// the styling for the description text container
export const descriptionTextContainer: SxProps = {
  width: '240px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2.75vh',
};

// the styling for the description text
export const descriptionText: SxProps = {
  fontFamily: 'Roboto',
  color: '#000000',
  fontSize: '14px',
  fontWeight: 'fontWeightRegular',
  lineHeight: '16px',
};

// the styling for the skip button
export const skipButton: SxProps = {
  position: 'absolute',
  width: '54px',
  height: '28px',
  right: '20px',
  top: '52px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '100px',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for the skip button text
export const skipButtonText: SxProps = {
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontWeight: 'fontWeightMedium',
  lineHeight: '18px',
  color: '#10ADC1',
  userSelect: 'none',
};

// the styling for the prev button
export const prevButton: SxProps = {
  position: 'absolute',
  width: '46px',
  height: '28px',
  left: '20px',
  bottom: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#10ADC1',
  borderRadius: '13.5px',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for the prev button text
export const prevButtonText: SxProps = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 'fontWeightRegular',
  lineHeight: '16.4px',
  color: '#ffffff',
  userSelect: 'none',
};

// the styling for the next button
export const nextButton: SxProps = {
  position: 'absolute',
  width: '46px',
  height: '28px',
  right: '20px',
  bottom: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#10ADC1',
  borderRadius: '13.5px',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for the next button text
export const nextButtonText: SxProps = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 'fontWeightRegular',
  lineHeight: '16.4px',
  color: '#ffffff',
  userSelect: 'none',
};
