import {AxiosInstance, AxiosRequestHeaders} from 'axios';
import {
  SignupParams,
  SocialLoginParams,
  LoginParams,
  ForgotPasswordParams,
  ValidateResetCodeParams,
  ResetPasswordParams,
  ProfileParams,
  UpdateProfileParams,
  ChangePasswordParams,
  UserAgreementParams,
} from './user.type';

export interface UserApiInterface {
  setup(instance: AxiosInstance): void;
  signup(params: SignupParams): Promise<any>;
  socialLogin(params: SocialLoginParams): Promise<any>;
  login(params: LoginParams): Promise<any>;
  forgetPassword(params: ForgotPasswordParams): Promise<any>;
  validateResetCode(params: ValidateResetCodeParams): Promise<any>;
  resetPassword(params: ResetPasswordParams): Promise<any>;
  fetchProfile(params: ProfileParams): Promise<any>;
  updateProfile(
    params: UpdateProfileParams,
    headers: AxiosRequestHeaders,
  ): Promise<any>;
  changePassword(
    params: ChangePasswordParams,
    headers: AxiosRequestHeaders,
  ): Promise<any>;
  deleteUserData(headers: AxiosRequestHeaders): Promise<any>;
  userAgreement(params: UserAgreementParams): Promise<any>;
}

/**
 * User account handling API.
 */
export class UserApi implements UserApiInterface {
  private instance?: AxiosInstance;

  setup(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Signup API.
   */
  async signup(params: SignupParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/register',
        JSON.stringify(params),
      );
      if (response.status === 409) {
        if (response.data) {
          throw new Error(response.data);
        }
        throw new Error('User already exists');
      }
      return response.data;
    }
    return null;
  }

  /**
   * Social login API.
   */
  async socialLogin(params: SocialLoginParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/login/social',
        {},
        {
          headers: {
            ...params,
          },
        },
      );
      return response.data;
    }
    return null;
  }

  /**
   * Login API.
   */
  async login(params: LoginParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/login',
        JSON.stringify(params),
      );
      if (response.status === 401) {
        if (response.data && response.data.MESSAGE) {
          throw new Error(response.data.MESSAGE);
        }
        throw new Error('snackbar.errorMessages.incorrectUserOrPassword');
      }
      return response.data;
    }
    return null;
  }

  /**
   * Forgot password API.
   */
  async forgetPassword(params: ForgotPasswordParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        `/account/forgotPassword/${params.email}`,
        JSON.stringify(params),
      );
      return response.data;
    }
    return null;
  }

  /**
   * Validate reset code API.
   */
  async validateResetCode(params: ValidateResetCodeParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/validateResetCode',
        JSON.stringify(params),
      );
      return response.data;
    }
    return null;
  }

  /**
   * Reset password API.
   */
  async resetPassword(params: ResetPasswordParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/resetPassword',
        JSON.stringify(params),
      );
      return response.data;
    }
    return null;
  }

  /**
   * Fetch user profile API.
   */
  async fetchProfile(params: ProfileParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/user/profile', {
        headers: {
          ...params,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * Update profile details API.
   */
  async updateProfile(
    params: UpdateProfileParams,
    headers: AxiosRequestHeaders,
  ): Promise<any> {
    if (this.instance) {
      const response = await this.instance.put(
        '/user/profile',
        JSON.stringify(params),
        {
          headers: {
            ...headers,
          },
        },
      );
      return response.data;
    }
    return null;
  }

  /**
   * Change user password API.
   */
  async changePassword(
    params: ChangePasswordParams,
    headers: AxiosRequestHeaders,
  ): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/account/changepassword',
        JSON.stringify(params),
        {
          headers: {
            ...headers,
          },
        },
      );

      if (response.status === 401) {
        throw new Error('fieldErrors.currentPassword.invalid');
      }
      return response.data;
    }
    return null;
  }

  /**
   * Delete user data API.
   */
  async deleteUserData(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.delete('/user/', {
        headers: {
          ...headers,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * User agreement API.
   */
  async userAgreement(params: UserAgreementParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post('/agreement/', {
        headers: {
          ...params,
        },
      });
      return response.data;
    }
    return null;
  }
}
