import Button, {ButtonProps} from '@mui/material/Button';
import {Text} from '..';

import * as Styles from './button.styles';

export function CustomButton(
  props: ButtonProps & React.HTMLProps<HTMLButtonElement>,
) {
  const {children, label, disabled = false, ...rest} = props;
  return (
    <Button
      style={disabled ? Styles.buttonDisabledContainer : Styles.buttonContainer}
      disabled={disabled}
      {...rest}
    >
      {children}
      <Text
        variant="body1"
        align="center"
        sx={children ? Styles.buttonTextWithChildren : Styles.buttonText}
        tx={label}
      />
    </Button>
  );
}
