import Box from '@mui/material/Box';
import {Text, Image} from '..';

import * as Styles from './poweredBy.styles';

export function PoweredBy() {
  return (
    <Box sx={Styles.poweredByContainer}>
      <Text
        variant="body1"
        align="center"
        sx={{
          color: '#000000',
          fontSize: '13px',
          fontWeight: 'fontWeightMedium',
          marginRight: '6px',
        }}
        tx={'common.poweredByText'}
      />
      <Image
        imgAlt={'99bridges'}
        imgSrc={require('../../assets/footer-logo.png')}
        imgHeight={26}
      />
    </Box>
  );
}
