import {Instance, SnapshotOut, types} from 'mobx-state-tree';

/**
 * Order History model
 */
export const OrderHistoryModel = types.model('orderHistory').props({
  id: types.maybe(types.string),
  amount: types.maybe(types.number),
  receipt_url: types.maybe(types.string),
  created: types.maybe(types.number),
  captured: types.maybe(types.boolean),
  paid: types.maybe(types.boolean),
  amount_captured: types.maybe(types.number),
  currency: types.maybe(types.string),
  calculated_statement_descriptor: types.maybe(types.string),
  amount_refunded: types.maybe(types.number),
  status: types.maybe(types.string),
});

type OrderHistoryType = Instance<typeof OrderHistoryModel>;
export interface OrderHistory extends OrderHistoryType {}
type OrderHistorySnapshotType = SnapshotOut<typeof OrderHistoryModel>;
export interface OrderHistorySnapshot extends OrderHistorySnapshotType {}
export const createOrderHistoryDefaultModel = () =>
  types.optional(OrderHistoryModel, {});
