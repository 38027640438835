import Box from '@mui/material/Box';
import {Text} from '..';

export function TermsAndConditions() {
  return (
    <Box>
      <Text variant="body1" align="center" sx={{color: '#000000'}}>
        {'By using the service, you agree to our '}
        <a
          style={{color: '#000000'}}
          href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
        >
          {'Terms of Service'}
        </a>
        {' and '}
        <a
          style={{color: '#000000'}}
          href={process.env.REACT_APP_PRIVACY_POLICY_URL}
        >
          {'Privacy Policy'}
        </a>
      </Text>
    </Box>
  );
}
