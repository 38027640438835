import {onSnapshot} from 'mobx-state-tree';
import _ from 'lodash';
import {RootStoreModel, RootStore} from './root.store';
import {Environment} from '../environment';
import {storageKeys} from '../../config/constants';
// import * as storage from '../../utils/storage';

/**
 * The key we'll be saving our state as within async storage.
 */
// const ROOT_STATE_STORAGE_KEY = 'root';

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment();
  await env.setup();
  return env;
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore;
  // let data: any;

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  try {
    const initStoreData: {
      user?: any;
    } = {
      user: {},
    };
    // load data from storage
    // data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || {};

    /**
     * Load user credential data from storage
     */
    let credentials = localStorage.getItem(storageKeys.loginCredentials) || '';
    if (credentials) {
      credentials = JSON.parse(credentials);
      initStoreData.user = {credentials};
    }

    /**
     * Load profile image data from storage
     */
    const profileImage = localStorage.getItem(storageKeys.profileImage) || '';
    if (!_.isEmpty(credentials) && profileImage) {
      initStoreData.user.profile = {image: profileImage};
    }

    if (!_.isEmpty(initStoreData)) {
      rootStore = RootStoreModel.create(initStoreData, env);
    } else {
      rootStore = RootStoreModel.create({}, env);
    }
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env);

    // but please inform us what happened
    // __DEV__ && console.error(e.message, null);
  }

  // track changes & save to storage
  onSnapshot(rootStore, snapshot => {
    if (snapshot) {
      /**
       * Store user credentials
       */
      if (snapshot.user && snapshot.user.credentials) {
        localStorage.setItem(
          storageKeys.loginCredentials,
          JSON.stringify(snapshot.user.credentials),
        );
      }

      /**
       * Store profile image
       */
      if (
        snapshot.user &&
        snapshot.user.profile &&
        snapshot.user.profile.image
      ) {
        localStorage.setItem(
          storageKeys.profileImage,
          snapshot.user.profile.image,
        );
      }
    }
  });

  return rootStore;
}
