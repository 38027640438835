import Box from '@mui/material/Box';
import {Text} from '..';

import {InputProps} from './input.props';
import * as Styles from './input.styles';

export function CustomInput(
  props: InputProps & React.HTMLProps<HTMLInputElement>,
) {
  const {inputIcon, endIcon, required, ...rest} = props;
  return (
    <Box sx={Styles.inputContainer}>
      <Box sx={Styles.inputIconContainer}>{inputIcon}</Box>
      <Box sx={Styles.requiredIconContainer}>
        {required && <Text sx={Styles.requiredIcon}>{'* '}</Text>}
      </Box>
      <input style={Styles.input} {...rest} />
      <Box sx={Styles.endIconContainer}>{endIcon}</Box>
    </Box>
  );
}
