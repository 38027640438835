import Box from '@mui/material/Box';
import {Text} from '..';

import {TabsProps} from './tabs.props';

export function Tabs(props: TabsProps) {
  const {data, activeTab, onChangeTab} = props;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          {data.map(tabItem => (
            <Box
              key={tabItem.id}
              sx={{
                position: 'relative',
                display: 'flex',
                marginRight: '25px',
                '&:hover': {
                  cursor: activeTab === tabItem.id ? 'default' : 'pointer',
                },
                pb: '9px',
              }}
              onClick={() =>
                activeTab === tabItem.id ? {} : onChangeTab(tabItem.id)
              }
            >
              <Text
                variant="h6"
                sx={{
                  color: activeTab === tabItem.id ? '#002C58' : '#00000030',
                  userSelect: 'none',
                  fontWeight: 'fontWeightSemiBold',
                }}
                tx={tabItem.name}
              />
              {activeTab === tabItem.id && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '15px',
                    height: '1.5px',
                    borderRadius: '3px',
                    backgroundColor: '#10ADC1',
                    zIndex: 999,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
