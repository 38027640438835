import Box from '@mui/material/Box';

import {Image} from '..';
import {LogoProps} from './logo.props';

export function Logo(props: LogoProps) {
  const {size = 40, ...rest} = props;
  return (
    <Box {...rest}>
      <Image
        imgAlt={'99bridges'}
        imgSrc={require('../../assets/logo.png')}
        imgHeight={size}
      />
    </Box>
  );
}
