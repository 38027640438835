import {CSSProperties} from 'react';
import {SxProps} from '@mui/material';

// the styling for select main container
export const selectMainContainer: SxProps = {
  width: '100%',
  borderRadius: '25px',
  backgroundColor: '#FFFFFF',
};

// the styling for the input container
export const inputContainer: SxProps = {
  zIndex: 5,
  backgroundColor: '#10ADC110',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '36px',
  borderRadius: '62px',
  border: '0.5px solid #10ADC1',
};

// the styling for input component
export const input: CSSProperties = {
  backgroundColor: 'transparent',
  border: 0,
  outline: 0,
  height: '33px',
  width: '100%',
  color: '#000000',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: 13,
  fontWeight: 500,
  padding: '0px 18px',
};

// the styling for options collapsed container
export const optionsCollapsedContainer: SxProps = {
  overflow: 'hidden',
  marginTop: '-36px',
  paddingTop: '36px',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '25px',
  maxHeight: '0px',
  transition: 'all 0.25s cubic-bezier(0, 1, 0, 1)',
};

// the styling for options expanded container
export const optionsExpandedContainer: SxProps = {
  overflow: 'hidden',
  marginTop: '-36px',
  paddingTop: '36px',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '25px',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
  height: 'auto',
  maxHeight: '9999px',
  transition: 'all 0.25s cubic-bezier(1, 0, 1, 0)',
};

// the styling for options container
export const optionsContainer: SxProps = {
  width: '100%',
  padding: '7px 0px',
};

// the styling for option container
export const optionContainer: SxProps = {
  width: '100%',
  padding: '5px 20px',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for option text
export const optionText: SxProps = {
  color: '#000000',
  fontSize: '13px',
  fontWeight: 500,
  userSelect: 'none',
};
