import {useState} from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';

import Box from '@mui/material/Box';

import {Image, Text} from '..';

import {TutorialProps} from './tutorial.props';
import * as Styles from './tutorial.styles';

export function Tutorial(props: TutorialProps) {
  const {data, onClickSkip} = props;
  const [currentSlide, setCurrentSlide] = useState(0);

  const tutorialData = data && data.length ? data : [];

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const skip = () => {
    setCurrentSlide(0);
    onClickSkip();
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  return (
    <Box sx={Styles.backgroundContainer}>
      <Box sx={Styles.tutorialMainContainer}>
        <Box sx={Styles.reuseIconContainer}>
          <Image
            imgAlt={'reuse'}
            imgSrc={require('../../assets/reuse.png')}
            imgHeight={50}
          />
        </Box>
        <Box sx={Styles.carouselContainerDiv}>
          {tutorialData.length && (
            <Carousel
              autoPlay={false}
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              swipeable={false}
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
            >
              {tutorialData.map((eachData: any) => (
                <div key={eachData.id} style={Styles.carouselItemContainerDiv}>
                  <div
                    style={{
                      backgroundImage: `url(${eachData.imgSrc})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '50vh',
                      backgroundPosition: 'center',
                    }}
                  />
                  <Box sx={Styles.titleTextContainer}>
                    <Text
                      align="center"
                      sx={Styles.titleText}
                      tx={eachData.title}
                    />
                  </Box>
                  <Box sx={Styles.descriptionTextContainer}>
                    <Text
                      align="center"
                      sx={Styles.descriptionText}
                      tx={eachData.description}
                    />
                  </Box>
                </div>
              ))}
            </Carousel>
          )}
          <Box sx={Styles.skipButton} onClick={skip}>
            <Text
              align="center"
              sx={Styles.skipButtonText}
              tx={'buttons.skip'}
            />
          </Box>
          {currentSlide > 0 && (
            <Box sx={Styles.prevButton} onClick={prev}>
              <Text
                align="center"
                sx={Styles.prevButtonText}
                tx={'buttons.prev'}
              />
            </Box>
          )}
          {currentSlide < tutorialData.length - 1 && (
            <Box sx={Styles.nextButton} onClick={next}>
              <Text
                align="center"
                sx={Styles.nextButtonText}
                tx={'buttons.next'}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
