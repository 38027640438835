const typography = {
  fontFamily: 'Montserrat, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontWeightBlack: 900,
  h1: {
    fontWeight: 700,
    // fontSize: "2rem",
    fontSize: '34px',
    letterSpacing: 0,
  },
  h2: {
    fontWeight: 700,
    // fontSize: "1.5rem",
    fontSize: '28px',
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 700,
    // fontSize: "1.375rem",
    fontSize: '22px',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 600,
    // fontSize: "1.25rem",
    fontSize: '20px',
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 600,
    // fontSize: "1.125rem",
    fontSize: '18px',
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 500,
    // fontSize: "1rem",
    fontSize: '16px',
    letterSpacing: 0,
  },
  subtitle1: {
    letterSpacing: 0,
  },
  subtitle2: {
    letterSpacing: 0,
  },
  body1: {
    letterSpacing: 0,
    fontSize: '14px',
  },
  body2: {
    letterSpacing: 0,
    fontSize: '12px',
  },
  button: {
    letterSpacing: 0,
  },
  caption: {
    letterSpacing: 0,
    fontSize: '10px',
  },
  overline: {
    letterSpacing: 0,
  },
};

export default typography;
