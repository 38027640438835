import {useState} from 'react';
import Box from '@mui/material/Box';

import {Text} from '..';

import {ReactComponent as DownSvg} from '../../assets/down.svg';
import {useClickOutside} from '../../hooks/useClickOutside';

import {SelectProps} from './select.props';
import * as Styles from './select.styles';

export function CustomSelect(
  props: SelectProps & React.HTMLProps<HTMLInputElement>,
) {
  const {inputIcon, required, optionsData, onSelectOption, ...rest} = props;
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useClickOutside(() => {
    setIsOpen(false);
  });

  const renderOptions = () => (
    <Box
      sx={
        isOpen
          ? Styles.optionsExpandedContainer
          : Styles.optionsCollapsedContainer
      }
    >
      <Box sx={Styles.optionsContainer}>
        {optionsData.map(option => (
          <Box
            key={option.value}
            sx={Styles.optionContainer}
            onClick={() => {
              onSelectOption(option.value);
              setIsOpen(false);
            }}
          >
            <Text sx={Styles.optionText}>{option.label}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box ref={selectRef} sx={Styles.selectMainContainer}>
      <Box sx={Styles.inputContainer} onClick={() => setIsOpen(!isOpen)}>
        <Box sx={Styles.inputIconContainer}>{inputIcon}</Box>
        <Box sx={Styles.requiredIconContainer}>
          {required && <Text sx={Styles.requiredIcon}>{'* '}</Text>}
        </Box>
        <input style={Styles.input} disabled {...rest} />
        <Box sx={Styles.endIconContainer}>
          <Box sx={Styles.downIconContainer}>
            <DownSvg
              height={7.25}
              width={15}
              style={isOpen ? {transform: 'rotate(-180deg)'} : {}}
            />
          </Box>
        </Box>
      </Box>
      {renderOptions()}
    </Box>
  );
}
