import {flow, Instance, SnapshotOut, types} from 'mobx-state-tree';
import {
  OnboardingParams,
  OnboardingResponse,
  SubscriptionParams,
  PaymentMethodResponse,
  OrderHistoryResponse,
  ActiveSubscriptionResponse,
} from '../../services/api/billing/billing.type';
import {OrderHistoryModel} from './orderHistory.store';

import {withEnvironment} from '../extensions/with-environment';

const OnboardingApiModel = types.model({
  customerId: types.maybe(types.string),
});

const PaymentMethodApiModel = types.model({
  brand: types.maybe(types.string),
  last4: types.maybe(types.string),
});

const OrderHistoryApiModel = types.array(OrderHistoryModel);

const SubscriptionApiModel = types.model({
  active: types.maybe(types.boolean),
  subscriptionID: types.maybe(types.string),
});

/**
 * Billing model.
 */
export const BillingModel = types
  .model('billing')
  .props({
    onboardingResponse: types.optional(OnboardingApiModel, {}),
    paymentMethod: types.optional(PaymentMethodApiModel, {}),
    orderHistory: types.optional(OrderHistoryApiModel, []),
    subscription: types.optional(SubscriptionApiModel, {}),
  })
  .extend(withEnvironment)
  .actions(self => ({
    /* eslint-disable no-param-reassign */
    saveOrderHistory: (props: any) => {
      if (props) {
        self.orderHistory = props;
      }
    },
    /* eslint-enable no-param-reassign */
  }))
  .actions(self => ({
    /* eslint-disable no-param-reassign */
    signupUser: (props: SnapshotOut<typeof OnboardingApiModel>) => {
      if (props.customerId) {
        self.onboardingResponse.customerId = props.customerId;
      }
    },
    savePaymentMethod: (props: SnapshotOut<typeof PaymentMethodApiModel>) => {
      if (props.brand) {
        self.paymentMethod.brand = props.brand;
      }
      if (props.last4) {
        self.paymentMethod.last4 = props.last4;
      }
    },
    saveActiveSubscription: (
      props: SnapshotOut<typeof SubscriptionApiModel>,
    ) => {
      if (props.active) {
        self.subscription.active = props.active;
      }
      if (props.subscriptionID) {
        self.subscription.subscriptionID = props.subscriptionID;
      }
    },
    /* eslint-enable no-param-reassign */
  }))
  .actions(self => ({
    /**
     * Onboard customer using phone and email.
     */
    onboard: flow(function* onboardCustomer(params: OnboardingParams) {
      const onboardResult: OnboardingResponse =
        yield self.environment.api.billing.onboardCustomer(params);
      self.signupUser(
        OnboardingApiModel.create({
          customerId: onboardResult.stripeId,
        }),
      );
      return onboardResult;
    }),

    /**
     * Subscribe using customer Id.
     */
    subscribe: flow(function* customerSubscribe(params: SubscriptionParams) {
      const subscriptionResult: SubscriptionParams =
        yield self.environment.api.billing.subscribe(params);
      return subscriptionResult;
    }),

    /**
     * Pay per use using customer Id.
     */
    payPerUse: flow(function* customerPayPerUse(params: SubscriptionParams) {
      const payPerUseResult: SubscriptionParams =
        yield self.environment.api.billing.payAsYouGo(params);
      return payPerUseResult;
    }),

    /**
     * Get payment method.
     */
    getPaymentMethod: flow(function* customerPaymentMethod(
      accessToken?: string,
    ) {
      const paymentMethodResult: PaymentMethodResponse =
        yield self.environment.api.billing.getPaymentMethod({
          Authorization: `Bearer ${accessToken}`,
        });
      self.savePaymentMethod(
        PaymentMethodApiModel.create({
          brand: paymentMethodResult.brand || undefined,
          last4: paymentMethodResult.last4 || undefined,
        }),
      );
      return paymentMethodResult;
    }),

    /**
     * Update payment method.
     */
    updatePaymentMethod: flow(function* customerUpdatePaymentMethod(
      accessToken?: string,
    ) {
      const updatePaymentMethodResult: string =
        yield self.environment.api.billing.updatePaymentMethod({
          Authorization: `Bearer ${accessToken}`,
        });
      return updatePaymentMethodResult;
    }),

    /**
     * Get order history list.
     */
    getOrderHistory: flow(function* customerOrderHistory(accessToken?: string) {
      const orderHistoryResult: OrderHistoryResponse[] =
        yield self.environment.api.billing.getOrderHistory({
          Authorization: `Bearer ${accessToken}`,
        });
      self.saveOrderHistory(
        OrderHistoryApiModel.create(
          orderHistoryResult.map(eachItem => ({
            id: eachItem.id,
            amount: eachItem.amount,
            receipt_url: eachItem.receipt_url,
            created: eachItem.created,
            captured: eachItem.captured,
            paid: eachItem.paid,
            amount_captured: eachItem.amount_captured,
            currency: eachItem.currency,
            calculated_statement_descriptor:
              eachItem.calculated_statement_descriptor,
            amount_refunded: eachItem.amount_refunded,
            status: eachItem.status,
          })),
        ),
      );
      return orderHistoryResult;
    }),

    /**
     * Get active subscription.
     */
    getActiveSubscription: flow(function* cancelCustomerSubscription(
      accessToken?: string,
    ) {
      const activeSubscriptionResult: ActiveSubscriptionResponse =
        yield self.environment.api.billing.getActiveSubscription({
          Authorization: `Bearer ${accessToken}`,
        });
      self.saveActiveSubscription(
        SubscriptionApiModel.create({
          active: activeSubscriptionResult.active,
          subscriptionID: activeSubscriptionResult.subscriptionID || undefined,
        }),
      );
      return activeSubscriptionResult;
    }),

    /**
     * Cancel subscription.
     */
    cancelSubscription: flow(function* cancelCustomerSubscription(
      accessToken?: string,
    ) {
      const cancelSubscriptionResult: string =
        yield self.environment.api.billing.cancelSubscription({
          Authorization: `Bearer ${accessToken}`,
        });
      return cancelSubscriptionResult;
    }),
  }));

type BillingType = Instance<typeof BillingModel>;

export interface Billing extends BillingType {}

type BillingSnapshotType = SnapshotOut<typeof BillingModel>;

export interface BillingSnapshot extends BillingSnapshotType {}

export const createBillingDefaultModel = () =>
  types.optional(BillingModel, {} as any);
