import {AxiosInstance, AxiosRequestHeaders} from 'axios';
import {OnboardingParams, SubscriptionParams} from './billing.type';

export interface BillingApiInterface {
  setup(instance: AxiosInstance): void;
  onboardCustomer(params: OnboardingParams): Promise<any>;
  subscribe(params: SubscriptionParams): Promise<any>;
  payAsYouGo(params: SubscriptionParams): Promise<any>;
  getPaymentMethod(headers: AxiosRequestHeaders): Promise<any>;
  updatePaymentMethod(headers: AxiosRequestHeaders): Promise<any>;
  getOrderHistory(headers: AxiosRequestHeaders): Promise<any>;
  getActiveSubscription(headers: AxiosRequestHeaders): Promise<any>;
  cancelSubscription(headers: AxiosRequestHeaders): Promise<any>;
}

/**
 * Billing handling API.
 */
export class BillingApi implements BillingApiInterface {
  private instance?: AxiosInstance;

  setup(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Customer onboarding API.
   */
  async onboardCustomer(params: OnboardingParams): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        'billing/customer',
        JSON.stringify(params),
      );
      return response;
    }
    return null;
  }

  /**
   * Customer subscription API.
   */
  async subscribe(params: SubscriptionParams): Promise<any> {
    const priceId =
      params && params.countryCode && params.countryCode === 'CA'
        ? process.env.REACT_APP_SUBSCRIPTION_PRICE_ID_CA
        : process.env.REACT_APP_SUBSCRIPTION_PRICE_ID;
    if (this.instance) {
      const response = await this.instance.post(
        `billing/subscribe?price_id=${priceId}&customer_id=${params.customerId}`,
        {},
      );
      return response.data;
    }
    return null;
  }

  /**
   * Customer pay as you go API.
   */
  async payAsYouGo(params: SubscriptionParams): Promise<any> {
    const priceId =
      params && params.countryCode && params.countryCode === 'CA'
        ? process.env.REACT_APP_PAY_PER_USE_PRICE_ID_CA
        : process.env.REACT_APP_PAY_PER_USE_PRICE_ID;
    if (this.instance) {
      const response = await this.instance.post(
        `billing/payasyougo?price_id=${priceId}&customer_id=${params.customerId}&kioskCode=${params.kioskCode}`,
        {},
      );
      return response.data;
    }
    return null;
  }

  /**
   * Get payment method API.
   */
  async getPaymentMethod(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/billing/paymentmethod', {
        headers: {
          ...headers,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * Update payment method API.
   */
  async updatePaymentMethod(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/billing/portal', {
        headers: {
          ...headers,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * Get order history API.
   */
  async getOrderHistory(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/billing/charges', {
        headers: {
          ...headers,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * Get active subscription API.
   */
  async getActiveSubscription(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.get('/billing/subscription', {
        headers: {
          ...headers,
        },
      });
      return response.data;
    }
    return null;
  }

  /**
   * Cancel subscription API.
   */
  async cancelSubscription(headers: AxiosRequestHeaders): Promise<any> {
    if (this.instance) {
      const response = await this.instance.post(
        '/billing/subscription/cancel',
        {},
        {
          headers: {
            ...headers,
          },
        },
      );
      return response.data;
    }
    return null;
  }
}
