import {SxProps} from '@mui/material';

// the styling for the profile image container
export const profileImageContainer: SxProps = {
  backgroundColor: '#10ADC1',
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for the modal content container
export const modalContentMainContainer: SxProps = {
  width: '100%',
  height: '100%',
  backgroundColor: '#000000',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
};

// the styling for the modal header container
export const modalHeaderContainer: SxProps = {
  position: 'absolute',
  top: 0,
  backgroundColor: '#FFFFFF',
  padding: '10px 10px 5px 10px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

// the styling for the close button container
export const closeButtonContainer: SxProps = {
  padding: '10px',
  borderRadius: '50%',
  '&:hover': {
    cursor: 'pointer',
  },
};

// the styling for the edit text
export const editText: SxProps = {
  fontWeight: 'fontWeightMedium',
  color: '#000000',
};

// the styling for the cropper container
export const cropperContainer: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
