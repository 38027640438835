import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {Logo} from '..';

export function Loader() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Logo
        size={100}
        sx={{
          '@keyframes pulse': {
            '0%': {
              opacity: 1,
            },
            '50%': {
              opacity: 0.4,
            },
            '100%': {
              opacity: 1,
            },
          },
          animation: 'pulse 1.5s ease-in-out 0.5s infinite',
          color: theme.palette.mode === 'light' ? 'grey.300' : 'grey.600',
          textAlign: 'center',
          px: 3,
          py: 8,
        }}
      />
    </Box>
  );
}
