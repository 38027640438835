import {SxProps} from '@mui/material';

// the styling for the button container
export const buttonContainer: object = {
  zIndex: 5,
  border: 0,
  backgroundColor: '#10ADC1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '45px',
  borderRadius: '62px',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
  paddingLeft: '15px',
  paddingRight: '15px',
};

// the styling for the disabled button container
export const buttonDisabledContainer: object = {
  zIndex: 5,
  border: 0,
  backgroundColor: '#10ADC170',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '45px',
  borderRadius: '62px',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
  paddingLeft: '15px',
  paddingRight: '15px',
};

// the styling for the button text
export const buttonText: SxProps = {
  color: '#FFFFFF',
  fontWeight: 'fontWeightSemiBold',
  userSelect: 'none',
};

// the styling for the button text with Children
export const buttonTextWithChildren: SxProps = {
  color: '#FFFFFF',
  fontWeight: 'fontWeightSemiBold',
  userSelect: 'none',
  marginLeft: '7px',
  marginTop: '2.5px',
};
