import {CSSProperties} from 'react';
import {SxProps} from '@mui/material';

// the styling for the input container
export const inputContainer: SxProps = {
  zIndex: 5,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '45px',
  borderRadius: '62px',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
  overflow: 'hidden',
};

// the styling for the input icon container
export const inputIconContainer: SxProps = {
  width: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '15px',
};

// the styling for the end icon container
export const endIconContainer: SxProps = {
  width: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '15px',
};

// the styling for the required icon container
export const requiredIconContainer: SxProps = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  width: '10px',
  height: '25px',
};

// the styling for the required icon
export const requiredIcon: SxProps = {
  color: '#DA1B1B',
};

// the styling for input component
export const input: CSSProperties = {
  backgroundColor: '#FFFFFF',
  border: 0,
  outline: 0,
  height: '40px',
  width: '100%',
  color: '#000000',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: 13,
  fontWeight: 500,
};
