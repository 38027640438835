import {useEffect, useRef} from 'react';

export const useClickOutside = (handler: any) => {
  const domNode = useRef<HTMLElement>();

  useEffect(() => {
    const handleClick = (e: any) => {
      if (domNode.current && !domNode.current.contains(e.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return domNode;
};
