import {ImageProps} from './image.props';

export function Image(props: ImageProps) {
  const {imgAlt, imgSrc, imgHeight, rounded = false, ...restProps} = props;
  return (
    <img
      alt={imgAlt}
      src={imgSrc}
      style={{height: imgHeight, borderRadius: rounded ? '50%' : '0px'}}
      {...restProps}
    />
  );
}
