import {useState} from 'react';
import Box from '@mui/material/Box';

import {Text} from '..';

import {useClickOutside} from '../../hooks/useClickOutside';

import {SelectProps} from './simpleSelect.props';
import * as Styles from './simpleSelect.styles';

export function SimpleSelect(
  props: SelectProps & React.HTMLProps<HTMLInputElement>,
) {
  const {optionsData, onSelectOption, ...rest} = props;
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useClickOutside(() => {
    setIsOpen(false);
  });

  const renderOptions = () => (
    <Box
      sx={
        isOpen
          ? Styles.optionsExpandedContainer
          : Styles.optionsCollapsedContainer
      }
    >
      <Box sx={Styles.optionsContainer}>
        {optionsData.map(option => (
          <Box
            key={option.value}
            sx={Styles.optionContainer}
            onClick={() => {
              onSelectOption(option.value);
              setIsOpen(false);
            }}
          >
            <Text sx={Styles.optionText}>{option.label}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box ref={selectRef} sx={Styles.selectMainContainer}>
      <Box sx={Styles.inputContainer} onClick={() => setIsOpen(!isOpen)}>
        <input style={Styles.input} disabled {...rest} />
      </Box>
      {renderOptions()}
    </Box>
  );
}
