import React, {useEffect, useState} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';

import AppRoutes from './AppRoutes';
import {Loader} from './components';
import SettingsProvider from './contexts/SettingsProvider';
import SnackbarProvider from './contexts/SnackbarProvider';
import usePageTracking from './hooks/usePageTracking';
import {RootStoreProvider} from './stores/root/root.context';
import {setupRootStore} from './stores/root/root.setup';
import {RootStore} from './stores/root/root.store';

function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  usePageTracking();

  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  if (!rootStore) return null;

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        suspense: true,
      },
    },
  });

  return (
    <React.Suspense fallback={<Loader />}>
      <RootStoreProvider value={rootStore}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <SnackbarProvider>
              <AppRoutes />
            </SnackbarProvider>
          </SettingsProvider>
        </QueryClientProvider>
      </RootStoreProvider>
    </React.Suspense>
  );
}

export default App;
