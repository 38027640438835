import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

import {TextProps} from './text.props';

export function Text(props: TextProps) {
  const {text, tx, children, ...rest} = props;
  const {t} = useTranslation();

  const i18nText = tx && t(tx);
  const content = i18nText || text || children;

  return <Typography {...rest}>{content}</Typography>;
}
