import axios, {AxiosInstance} from 'axios';

import {UserApi, UserApiInterface} from './user/user.api';
import {BillingApi, BillingApiInterface} from './billing/billing.api';
import {ApiConfig, DEFAULT_API_CONFIG} from './api.config';

export interface ApiInterface {
  user: UserApiInterface;
  billing: BillingApiInterface;
  setup(): void;
}

export class Api implements ApiInterface {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  instance?: AxiosInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  user: UserApiInterface;

  billing: BillingApiInterface;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.user = new UserApi();
    this.billing = new BillingApi();
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.instance = axios.create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.instance.interceptors.response.use(
      res => res,
      error => {
        if (error.response.status === 500) {
          throw new Error('common.somethingWentWrong');
        }
        if (error.response.status === 409) {
          return error.response;
        }
        // if (error.response.status === 404) {
        //   throw error.response;
        // }
        if (error.response.status === 403) {
          throw new Error(error.response.data.message);
        }
        if (error.response.status === 401) {
          return error.response;
        }
        if (error.response.status !== 200) {
          throw new Error(error.response);
        }
        return error.response;
      },
    );
    this.user.setup(this.instance);
    this.billing.setup(this.instance);
  }
}
