import {lazy} from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, Route, Routes} from 'react-router-dom';
import {PrivateRoutes, PublicRoutes} from './components';

// Core
const Signup = lazy(() => import('./pages/signup'));
const Checkout = lazy(() => import('./pages/checkout'));
const Login = lazy(() => import('./pages/login'));
const Admin = lazy(() => import('./pages/admin'));
const ForgotPassword = lazy(() => import('./pages/forgotPassword'));
const VerifyCode = lazy(() => import('./pages/verifyCode'));
const NewPassword = lazy(() => import('./pages/newPassword'));
const ChangePassword = lazy(() => import('./pages/changePassword'));
const PaymentUpdate = lazy(() => import('./pages/paymentUpdate'));

const AppRoutes = observer(() => (
  <Routes>
    <Route path="/" element={<Signup />} />
    <Route path="/signup" element={<Navigate replace to="/" />} />
    <Route path="/checkout" element={<Checkout />} />
    <Route path="" element={<PublicRoutes />}>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify-code" element={<VerifyCode />} />
      <Route path="/new-password" element={<NewPassword />} />
    </Route>
    <Route path="/" element={<PrivateRoutes />}>
      <Route path="/user_admin" element={<Admin />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/paymentUpdate" element={<PaymentUpdate />} />
    </Route>
  </Routes>
));

export default AppRoutes;
