import {xssTags} from './constants';

/**
 * Function to handle phone validation
 */
export const validatePhone = (phone: string) => {
  const reg = /^\d{10,15}$/; // eslint-disable-line no-useless-escape
  return reg.test(phone);
};

/**
 * Function to handle email validation
 */
export const validateEmailAddress = (emailAddress: string) => {
  const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape
  return reg.test(emailAddress);
};

/**
 * Function to handle password validation
 */
export const validatePassword = (password: string) => {
  const reg = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; // eslint-disable-line no-useless-escape
  return reg.test(password);
};

/**
 * Function to parse name from email
 */
export const parseNameFromEmail = (email: string) => email.split('@')[0];

/**
 * Function to generate username from email.
 */
export const generateUserName = (email: string) => {
  if (email) {
    return `@${parseNameFromEmail(email)}`;
  }
  return '';
};

/**
 * Function to handle xss validation
 */
export const validateXSS = (value: any) => {
  if (value) {
    const xss = xssTags.find(tag => tag.test(value));
    if (xss) {
      return false;
    }
  }
  return true;
};

export const getBase64 = (file: any) =>
  new Promise(resolve => {
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load something...
    reader.onload = () => {
      resolve(reader.result);
    };
  });
