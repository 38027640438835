import {Instance, SnapshotOut, types, applySnapshot} from 'mobx-state-tree';

import {UserModel} from '../user/user.store';
import {BillingModel} from '../billing/billing.store';

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model('RootStore').props({
  user: types.optional(UserModel, {} as any),
  billing: types.optional(BillingModel, {} as any),
})
  .actions(self => ({
    resetStore() {
      applySnapshot(self, {});
    },
    forceLogout() {
      this.resetStore();
    }
  }));

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
